<script>
import Datepicker from "vue3-datepicker";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';

/**
 * Orders component
 */

export default {
    components: {
        Layout,
        PageHeader,
        Datepicker
    },
    data() {
        return {
            picked: "",
          loading: true,     daterange: "",
            title: "Historique des alertes agents",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "Alertes",
                    active: true,
                },
            ],
            alerts: [],
            alertData: [],
            reqError: null,
        };
    },
    computed: {
        /**
         * Total no. of records
         */
    },
    mounted() {
        this.getAlertsList();
    },
    methods: {
        ...reqMethods,
        searchFilter(e) {
            const searchStr = e.target.value;
            this.alertData = this.alerts.filter((alert) => {
                return (
                    alert.title.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.thematic.toLowerCase().includes(searchStr.toLowerCase())
                );
            });
        },
        getAlertsList() {
            const that = this;
          this.loading = true; this.getRequest('/alert/alert-agent/')
                .then(
                    function (response) {
                        that.alerts = response.data;
                        that.alertData = response.data;
                        console.log(that.alertData);
                    },
                    function (error) {
                        that.reqError = error;
                    }
                ) .finally(() => {
              that.loading = false;
            });
        },

    },
};
</script>

<template >
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-3">Liste des alertes</h4>
                        <div class="row justify-content-between">
                            <div class="col-md-5">
                                <div class="form-group mt-3 mb-0">
                                    <div class="search-box me-2">
                                        <div class="position-relative">
                                            <input type="text" class="form-control bg-soft bg-info border-0"
                                                placeholder="Rechercher..." @input="searchFilter($event)" />

                                            <i class="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-md-6 justify-content-end">
                                <div class="form-group col-md-4 mt-3 mb-0">
                                    <datepicker v-model="picked" :first-day-of-week="1" lang="en" confirm class="form-control"
                                        placeholder="Date">
                                    </datepicker>
                                </div>
                                <div class="mt-3 col-md-4 mb-0">
                                    <button class="btn btn-info" @click="dateFilter">
                                        <i class="bx bx-slider align-middle me-1"></i>
                                        Filtrer
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mt-3" v-if="!loading">
                            <table class="table table-nowrap table-centered mb-0 align-middle">
                                <thead class="table-light">
                                    <tr>
                                        <th>Référence</th>
                                        <th>Titre</th>
                                        <th>Thématique</th>
                                        <th scope="col">Urgence</th>
                                        <th scope="col">Etat</th>
                                        <th scope="col">Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(alert, index) in alertData" :key=index>
                                        <td> {{ alert.short_code }} </td>
                                        <td> {{ alert.title }} </td>
                                        <td> {{ alert.thematic.name }} </td>
                                        <td v-if="alert.urgency_level === 0">
                                            <span class="badge badge-pill badge-soft-success font-size-11">
                                             Normal
                                            </span>
                                        </td>
                                        <td v-else-if="alert.urgency_level === 1">
                                            <span class="badge badge-pill badge-soft-warning font-size-11">
                                              Urgent
                                            </span>
                                        </td>
                                      <td v-else>
                                            <span class="badge badge-pill badge-soft-warning font-size-11">
                                              Normal
                                            </span>
                                      </td>

                                        <td v-if="alert.send == true"><span class="text-success">Publiée</span></td>
                                        <td v-if="alert.send == false"><span class="text-danger">Non Publié</span></td>
                                        <td> {{ alert.created_at.split('T')[0] }} </td>
                                        <td>
                                            <button @click="$router.push({ path: `/agentAlertDetail/${alert.id}` })"
                                                class="btn btn-info btn-sm me-2 w-xs">
                                                Détails
                                            </button>
                                            <button @click="$router.push({ path: `/agentsResponses/${alert.id}` })"
                                                class="btn btn-success me-2 btn-sm w-xs">
                                                Réponses
                                                <span class="badge bg-danger rounded-pill"> {{ alert.non_lus }} </span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      <div v-else class="loading-container">
                        <div class="text-center">
                          <i class="bx bx-loader bx-spin" style="font-size: 5em;"></i>
                          <h1>Chargement</h1>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh
}
</style>